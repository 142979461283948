import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'white',
    [theme.breakpoints.up('lg')]: {
      marginTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  image: {
    height: 66,
    width: '60%',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.14rem',
  },
  content: {
    textAlign: 'justify',
    fontSize: '1.14rem',
    lineHeight: '1.5',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  contentUl: {
    textAlign: 'justify',
    fontSize: '1.14rem',
    lineHeight: '1.5',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  winfireImage: {
    width: '100%',
    height: 'auto',
  },
  header1: {
    marginTop: theme.spacing(5),
  },
  contentItem1: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '2.77%',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.5rem',
      paddingRight: '0rem',
    },
  },
  contentItem2: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '1.39%',
      paddingLeft: '1.39%',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5rem',
    },
  },
  contentItem3: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2.77%',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.5rem',
      paddingRight: '0rem',
    },
  },
}));

function IntroWinfire() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Grid container>
          <Grid container item xs={12} className={classes.winfireContainer}>
            <Grid item xs={12} md={4} sm={6} lg={4} xl={4} className={classes.contentItem1}>
              <div>
                <Typography className={classes.content}>
                  Ống cứu hỏa Thương hiệu WinFire là một sản phẩm chất lượng cao
                  của Chinh Dai Industrial, dẫn đầu về sản lượng tại
                  Việt Nam xuất sang các thị trường quốc tế như Australia,
                  Hong Kong với sản lượng lên tới gần 13,000 tấn/năm.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4} xl={4} className={classes.contentItem2}>
              <div>
                <Typography className={classes.content}>
                  <b> Đặc tính: </b>
                  Ống cứu hỏa chuyên dụng WinFire được thiết kế,
                  tạo rãnh theo nhu cầu của công trình dân dụng và công nghiệp.
                  Sử dụng giải pháp coupling được sử dụng bởi các nước tiên tiến
                  trên thế giới do Công ty Victaulic phát triển tại Mỹ từ năm 1919,
                  sản phẩm đáp ứng tiêu chuẩn khắt khe như ASTM A500, A53, đang
                  trong quá trình kiểm định và cấp chứng chỉ bởi tổ chức UL - Hoa Kỳ
                  và AS 4118 - Úc.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4} xl={4} className={classes.contentItem3}>
              <div>
                <Typography className={classes.content}>
                  <b>Giải pháp: </b>
                  Ống rãnh dùng khớp nối coupling + gasket thay thế Hàn
                  và Ren trong công trình Phòng cháy chữa cháy giúp:
                </Typography>
                <br />
                <ul className={classes.contentUl}>
                  <li className={classes.content}>
                    Tăng tính an toàn trong quá trình thi công, đặc biệt với các công trình
                    có vật liệu dễ cháy, nổ do không cần dùng mối hàn hay kiểm tra bằng
                    X-quang mối hàn
                  </li>
                  <li className={classes.content}>
                    Giảm thời gian thi công xuống đáng kể, không cần
                    nhân lực có chứng chỉ hành nghề kĩ thuật cao
                  </li>
                  <li className={classes.content}>
                    Tiện dụng trong bảo trì, bảo hành do dễ dàng
                    tháo lắp kiểm tra từng phân đoạn
                  </li>
                  <li className={classes.content}>
                    Tiết kiệm lớn chi phí về con người, dụng cụ, phụ kiện, phương pháp
                    test, và các chi phí lãi vay do kéo dài thời gian dự án nếu
                    thi công bằng phương pháp truyền thống.
                  </li>
                </ul>
                <br />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(useStyles)(IntroWinfire);
