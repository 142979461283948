import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      marginTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  content: {
    fontSize: '1.14rem',
    lineHeight: '1.5',
  },
  header1: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
      marginBottom: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0rem',
    },
    fontWeight: 'bold',
  },
  textFont: {
    marginTop: '1rem',
    fontSize: '1.14rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textFont1: {
    fontSize: '0.875rem',
  },
  imageContainer: {
    display: 'flex',
  },
  imgCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
  },
}));

function AppliedTech() {
  const classes = useStyles();

  return (
    <section id="cong-nghe-tao-ranh" className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.header1}> CÔNG NGHỆ NỐI ỐNG TẠO RÃNH </Typography>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Typography className={classes.content}>
              Công nghệ nối ống có rãnh phổ biến ngày nay ra đời
              vào năm 1919 khi Ernest Tribe, một Trung úy, Kỹ sư Hoàng
              gia Anh và Tiến sĩ Henry Selby Hele-Shaw, một kỹ sư nổi tiếng,
              hoàn thành đăng kí bằng sáng chế phát minh khớp nối ống có rãnh.
              Ban đầu được biết đến với tên gọi Công ty Liên doanh Ống Victory,
              Victaulic bắt đầu tiếp thị sản phẩm mang tính cách mạng này
              ra thị trường gia công đường ống.
              Gần 100 năm sau, công nghệ nối ống có rãnh tiếp tục phát triển và thay
              đổi cách nhìn của ngành xây dựng về hệ thống đường ống.
              Các khớp nối, phụ kiện & phụ kiện có rãnh Victaulic cung cấp phương
              thức nối ống nhanh hơn, dễ dàng hơn và an toàn hơn bao giờ hết
            </Typography>
            <br />
            <Typography className={classes.content}>
              <b> VẬY CÔNG NGHỆ NỐI ỐNG CÓ RÃNH LÀ GÌ? </b>
            </Typography>
            <br />
            <Typography className={classes.content}>
              Công nghệ nối ống có rãnh là sự kết hợp của bốn yếu tố
            </Typography>
            <br />
            <ul>
              <li>
                <Typography className={classes.content}>
                  <b>Ống có rãnh</b>
                </Typography>
              </li>
              <li>
                <Typography className={classes.content}>
                  <b>Miếng đệm (Gioăng)</b>
                </Typography>
              </li>
              <li>
                <Typography className={classes.content}>
                  <b>Khớp nối</b>
                </Typography>
              </li>
              <li>
                <Typography className={classes.content}>
                  <b>Đai ốc và Bu lông</b>
                </Typography>
              </li>
            </ul>
            <Typography className={classes.content}>
              Rãnh ống được tạo ra bằng cách tạo hình hoặc gia công rãnh
              thực tế vào hai đầu của hai ống. Một miếng đệm bao bọc xung
              quanh và phía trên đầu ống, hai khớp nối quanh miếng đệm và
              nằm trong các rãnh ống, các bu lông và đai ốc được siết chặt
              bằng cờ lê để giữ hai đường ống lại với nhau.
            </Typography>
            <img src="https://www.victaulic.com/wp-content/uploads/2017/05/grooved-coupling-5.png.webp" alt="tech" style={{ maxWidth: '100%' }} />
          </Grid>
          <Grid item xs={0} lg={1} />
          <Grid item xs={12} lg={6}>
            <img src="https://www.victaulic.com/wp-content/uploads/2017/04/Style-177N-Cutaway.png.webp" alt="tech" style={{ maxWidth: '100%' }} />
            <div className={classes.imageContainer}>
              <img src="https://www.victaulic.com/wp-content/uploads/2017/04/1919-patent-drawing-186x300.png.webp" alt="tech" className={classes.imgCenter} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.content}>
              <b> CÓ HAI PHƯƠNG THỨC KHỚP NỐI CƠ BẢN: </b>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src="https://www.victaulic.com/wp-content/uploads/2017/05/grooved-coupling.png.webp" alt="tech" style={{ maxWidth: '100%' }} />
            <Typography className={classes.content}>
              <b> Khớp nối rãnh linh hoạt </b>
            </Typography>
            <Typography className={classes.content}>
              Khớp nối linh hoạt này cho phép phần ống nối chuyển động góc ở một mức nhất định.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <img src="https://www.victaulic.com/wp-content/uploads/2017/05/grooved-coupling-rigid-4.png.webp" alt="tech" style={{ maxWidth: '100%' }} />
            <Typography className={classes.content}>
              <b> Khớp nối rãnh siết cứng </b>
            </Typography>
            <Typography className={classes.content}>
              Khớp nối này không cho phép ống bẻ cong và có thể sử dụng ở
              bất cứ đâu cần cố định mối nối, tương tự như mối nối mặt bích
              hoặc mối hàn
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(useStyles)(AppliedTech);
