/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const styles = (theme) => ({
  root: {
    // backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundBlendMode: 'overlay',
    backgroundColor: '#444',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
      height: '66vh',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem',
    },
    backgroundImage: `url(${'https://static.chinhdaisteel.com/Winfire/shuttlerstock-image/shutterstock_647070460.jpg'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  button: {
    backgroundColor: '#3f51b5',
    fontFamily: [
      'Roboto', 'Helvetica Neue', 'Arial',
    ].join(','),
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2rem',
      fontSize: '1rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '6rem',
      fontSize: '1.14rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '0.6rem',
      paddingRight: '0.6rem',
      borderRadius: 4,
      width: '20%',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    color: 'white',
  },
  subTitle: {
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    color: 'white',
    fontSize: '1.33rem',
    paddingTop: '1rem',
  },
  container: {
    height: '30vh',
    marginTop: '25vh',
  },
});

function Contact(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" marked="center" align="center" component="h1" className={classes.title}>
              VỀ WINFIRE
            </Typography>
            <Typography align="center" className={classes.subTitle}>
              Thương hiệu
              <b> tiên phong </b>
              trong lĩnh
              vực ống thép cứu hỏa chuyển dụng
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(styles)(Contact);
