import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/navbar/navbarvn';
import Banner from '../components/winfirevn/banner';
import IntroWinfire from '../components/winfirevn/gioithieuwinfire';
import AppliedTech from '../components/winfirevn/congnghe';
import Safety from '../components/winfirevn/safety';
import Quality from '../components/winfirevn/quality';
import Product from '../components/winfirevn/products';
import Contact from '../components/winfirevn/lien-he';
import Footer from '../components/homepagevn/Footer';

const WinfirePage = () => (
  <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title> GIỚI THIỆU WINFIRE</title>
    </Helmet>
    <Navbar />
    <Banner />
    <IntroWinfire />
    <AppliedTech />
    <Product />
    <Safety />
    <Quality />
    <Contact />
    <Footer />
  </>
);

export default WinfirePage;
