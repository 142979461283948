import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import SecurityIcon from '@material-ui/icons/Security';
import EcoIcon from '@material-ui/icons/Eco';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      marginTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.14rem',
  },
  content: {
    textAlign: 'center',
    fontSize: '1.14rem',
    lineHeight: '1.5',
  },
  winfireImage: {
    width: '100%',
    height: '60vh',
  },
  header1: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
    marginBottom: '2rem',
    fontWeight: 'bold',
  },
  contentItem1: {
    paddingRight: '2.77%',
    paddingBottom: '1rem',
  },
  contentItem2: {
    paddingRight: '1.39%',
    paddingLeft: '1.39%',
    paddingBottom: '1rem',
  },
  contentItem3: {
    paddingLeft: '2.77%',
    paddingBottom: '1rem',
  },
  item: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '2rem 1.5rem 2rem 1.5rem',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '1.5rem 1rem',
    },
  },
  image: {
    height: 66,
    width: '60%',
  },
  header2: {
    fontSize: '1.14rem',
    color: '#888888',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '15%',
      marginRight: '15%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '10%',
      marginRight: '10%',
    },
  },
  safetyContent: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
  },
}));

function Safety() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.header1}> YẾU TỐ AN TOÀN </Typography>
            <Typography align="center" className={classes.header2}>
              Tính an toàn cao sẽ tạo dựng hình ảnh tốt đối với công chúng.
              Với những dự án quy mô lớn, các rủi ro (cháy, nổ, sự cố về nhân mạng)
              thường rất cao nên dễ ảnh hưởng đến tiến độ thi công và đến hình
              ảnh - tên tuổi của dự án.
            </Typography>
          </Grid>
          <Grid container item xs={12} className={classes.safetyContent}>
            <Grid item xs={12} sm={6} md={4} className={classes.contentItem1}>
              <div className={classes.item}>
                <SecurityIcon style={{ fontSize: 60, color: '#f2ae1c' }} />
                <br />
                <Typography variant="h5" className={classes.content}>
                  Mối ghép không dùng nhiệt như phương pháp hàn nên không gây cháy nổ;
                  không gây tổn thương, hay gây điện giật.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.contentItem2}>
              <div className={classes.item}>
                <EcoIcon style={{ fontSize: 60, color: '#f2ae1c' }} />
                <br />
                <Typography variant="h5" className={classes.content}>
                  Không dùng X-quang để kiểm tra mối hàn, đảm bảo an
                  toàn cho công nhân và môi trường
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.contentItem3}>
              <div className={classes.item}>
                <StarBorderIcon style={{ fontSize: 60, color: '#f2ae1c' }} />
                <br />
                <Typography variant="h5" className={classes.content}>
                  Đặc biệt với các dự án không thể dùng phương pháp hàn với các
                  vật liệu dễ cháy nổ như dầu, sơn, khí cháy thì đây là giải pháp
                  có thể coi là tối ưu nhất
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(useStyles)(Safety);
