import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    marginTop: '5rem',
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    display: 'flex',
    position: 'relative',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
  },
  header2: {
    fontSize: '1.14rem',
    color: '#888888',
    marginBottom: theme.spacing(5),
  },
  textFont: {
    marginTop: '1rem',
    fontSize: '1.14rem',
    fontWeight: 'bold',
    textDecoration: 'none !important',
  },
  textFont1: {
    fontSize: '0.875rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: theme.spacing(5),
    testAlign: 'center',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    fontSize: '1.14rem',
    textDecoration: 'none',
    padding: '1rem 1.5rem 1rem 1.5rem',
    borderRadius: '41px',
    backgroundColor: '#f2ae1c',
    color: 'white',
  },
  quotationContainer: {
    width: '100%',
    marginTop: '6rem',
    height: 'auto',
    backgroundColor: '#1b1c46',
  },
}));

function ProductMenu() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div className={classes.quotationContainer}>
        <Grid container>
          <Grid item xs={1} lg={1} />
          <Grid item xs={10} lg={10}>
            <br />
            <Typography variant="h6" align="center" style={{ marginTop: '3rem', color: 'white' }}>
              Công ty TNHH Công Nghiệp Chính Đại với 20 năm kinh nghiệm trong
              lĩnh vực sản xuất các loại ống thép, đi đầu trong việc cung cấp ống thép
              cứu hỏa chuyên dụng ở Việt Nam
            </Typography>
            <br />
            <Typography variant="h4" align="center" style={{ color: 'white' }}>
              NHẬN BÁO GIÁ NGAY BÂY GIỜ
            </Typography>
            <br />
            <Typography gutterBottom variant="h6" component="h2" className={classes.textFont} align="center" style={{ marginBottom: '5rem' }}>
              <a href="/lien-he" className={classes.actionButton}> Liên hệ ngay với chúng tôi </a>
            </Typography>
          </Grid>
          <Grid item xs={1} lg={1} />
        </Grid>
      </div>
    </section>
  );
}

export default withStyles(useStyles)(ProductMenu);
