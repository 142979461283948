import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import QualityImg from '../../images/yeu-to-chat-luong.png';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      marginTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  content: {
    textAlign: 'justify',
    fontSize: '1.14rem',
    lineHeight: '1.5',
    padding: '0rem 1rem',
  },
  header1: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
    marginBottom: '2rem',
    fontWeight: 'bold',
  },
  textFont: {
    marginTop: '1rem',
    fontSize: '1.14rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textFont1: {
    fontSize: '0.875rem',
  },
  contentItem1: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: '2.77%',
      paddingBottom: '1rem',
    },
  },
  contentItem2: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '2.77%',
      paddingBottom: '1rem',
    },
  },
}));

function QualityFactor() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <Typography variant="h4" marked="center" align="center" component="h2" className={classes.header1}> YẾU TỐ CHẤT LƯỢNG </Typography>
          </Grid>
          <Grid container item xs={12} lg={12}>
            <Grid item xs={12} sm={6} md={6} className={classes.contentItem1}>
              <Typography variant="h5" className={classes.content}>
                <li> Chi phí vận hành thấp và tuổi thọ hệ thống ống gia tăng </li>
              </Typography>
              <br />
              <Typography variant="h5" className={classes.content}>
                <li>
                  Có khe hở bù co giãn (expansion & contraction) do nhiệt
                  hoặc lực kéo nén của hệ ống gây ra. (hình trên)
                </li>
              </Typography>
              <br />
              <Typography variant="h5" className={classes.content}>
                <li> Cho phép hai ống được gấp khúc (deflection). (hình dưới) </li>
              </Typography>
              <br />
              <img src={QualityImg} alt="QualityImg" style={{ maxWidth: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.contentItem2}>
              <Typography variant="h5" className={classes.content}>
                <li>
                  Hãm rung và giảm ồn; chịu được động đất do rung động không truyền trực tiếp
                  qua ống kế tiếp vì có khe hở, mà chỉ truyền qua lưu chất nên rung
                  động bị triệt tiêu sau 3 khớp nối mềm,
                </li>
              </Typography>
              <br />
              <Typography variant="h5" className={classes.content}>
                <li>
                  Lớp mạ kẽm chống ăn mòn mặt trong và ngoài ống
                  không bị phá huỷ so với phương pháp hàn
                </li>
              </Typography>
              <br />
              <Typography variant="h5" className={classes.content}>
                <li>
                  Có thể chọn gasket của khớp nối phù hợp với từng lưu chất
                  (nước, khí, thực phẩm, dầu, gas, hoá chất, nước thải thô, chất ăn mòn…).
                </li>
              </Typography>
              <br />
              <Typography variant="h5" className={classes.content}>
                <li>
                  Có khả năng đấu nối ống đến Ø3.050mm (120 inch);
                  áp lực 310bar (4.500psi), chuyển vị  đến 5 độ.
                </li>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default withStyles(useStyles)(QualityFactor);
